import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "sonner";
import "./Style/index.css";
import { ThemeProvider } from "@material-tailwind/react";
import { composeWithDevTools } from "@redux-devtools/extension";
import { Provider } from "react-redux";
import rootReducer from "./reducers/index.js";
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { getUser } from "./actions/user.actions";
import "remixicon/fonts/remixicon.css";
import InternetConnectionStatus from "./Component/Log/InternetConnectionStatus.js";
import CheckSession from "./Component/Log/Checksession.js";

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

store.dispatch(getUser());

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <BrowserRouter>
      <Toaster position="bottom-left" expand={false} richColors closeButton />
      <CheckSession />
      <InternetConnectionStatus />
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);
