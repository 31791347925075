import React from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import {
  PresentationChartBarIcon,
  UserPlusIcon,
  PowerIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import axios from "axios";
import LOGO from "../asset/logo.png";

export default function Menu({ user }) {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const handleLogout = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/user/logout`,
          {},
          { withCredentials: true }
        )
        .then(() => {
          window.location = "/";
        });
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <Card className="h-[calc(100vh)] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5">
      <div className="mb-2 p-4">
        <Typography variant="h3" color="blue-gray">
          <p className="mb-4 overflow-hidden truncate w-full">
            <img src={LOGO} alt="logo" />
          </p>
          <p className="text-center text-sm">Bienvenue {user.user.fullName}</p>
        </Typography>
      </div>
      <List>
        <Accordion
          open={open === 1}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${
                open === 1 ? "rotate-180" : ""
              }`}
            />
          }
        >
          <ListItem className="p-0" selected={open === 1}>
            <AccordionHeader
              onClick={() => handleOpen(1)}
              className="border-b-0 p-3"
            >
              <ListItemPrefix>
                <PresentationChartBarIcon className="h-5 w-5" />
              </ListItemPrefix>
              <Typography color="blue-gray" className="mr-auto font-normal">
                Dashboard
              </Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-1">
            <List className="p-0">
              <Link to="/">
                <ListItem>
                  <ListItemPrefix>
                    <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                  </ListItemPrefix>
                  Mes widgets
                </ListItem>
              </Link>
            </List>
          </AccordionBody>
        </Accordion>
        <hr className="my-2 border-blue-gray-50" />
        {user?.user?.role === "admin" ? (
          <Link to={`/admin985`}>
            <ListItem>
              <ListItemPrefix className="text-red-500">
                <UserPlusIcon className="h-5 w-5" />
              </ListItemPrefix>
              Administration
            </ListItem>
          </Link>
        ) : null}
        <ListItem>
          <ListItemPrefix>
            <ExclamationTriangleIcon className="h-5 w-5" />
          </ListItemPrefix>
          <a href="mailto:support-prod@local.fr">Signaler un bug</a>
        </ListItem>
        <ListItem onClick={handleLogout}>
          <ListItemPrefix>
            <PowerIcon className="h-5 w-5" />
          </ListItemPrefix>
          Déconnexion
        </ListItem>
      </List>
    </Card>
  );
}
