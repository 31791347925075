import React from "react";
import ListWidget from "./Component/ListWidget";

const Dashboard = ({ userId }) => {
  return (
    <div className="w-full  p-6">
      <ListWidget userId={userId} />
    </div>
  );
};

export default Dashboard;
