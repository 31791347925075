import { useSelector } from "react-redux";
import Login from "./Component/Log/Login";
import Signup from "./Component/Log/Signup";
import Layout from "./Dashboard/Layout";
import Home from "./Pages/Landing";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import CreateWidget from "./Dashboard/Component/TaxiWidget/CreateWidget";
import IframePage from "./Dashboard/Component/TaxiWidget/Iframe";
import AdminDashboard from "./Dashboard/Component/Admin/AdminDashboard";

function AdminRoute({ element }) {
  const user = useSelector((state) => state.userReducer);
  if (user?.user?.role !== "admin") {
    return <Navigate to="/" />;
  }
  return element;
}

function App() {
  const user = useSelector((state) => state.userReducer);
  return (
    <Routes>
      {user?.user?.email && (
        <Route element={<Layout user={user} />}>
          <Route index element={<Dashboard userId={user.user._id} />} />
          <Route
            path="/createTaxiWidget"
            element={<CreateWidget userId={user.user._id} />}
          />
          <Route
            path="/admin985"
            element={<AdminRoute element={<AdminDashboard />} />}
          />
          <Route
            path="/createUser"
            element={<AdminRoute element={<Signup />} />}
          />
        </Route>
      )}
      <Route path="*" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/iframePage/:widgetId" element={<IframePage />} />
      <Route path="/signup" element={<Signup />} />
    </Routes>
  );
}

export default App;
