import Menu from "./Menu";
import { Outlet } from "react-router-dom";

export default function Layout({ user }) {
  return (
    <div className="flex ">
      <Menu user={user} />
      <Outlet />
    </div>
  );
}
