import { useState, useEffect } from "react";

/**
 * Un hook personnalisé pour appliquer un délai (debounce) sur une valeur.
 * Cela peut être utile pour réduire le nombre de mises à jour ou d'opérations
 * (par exemple, des requêtes HTTP) en réaction à des changements rapides d'une valeur (comme une saisie utilisateur).
 *
 * @param {any} value La valeur à debouncer.
 * @param {number} delay Le délai en millisecondes avant de considérer la dernière valeur comme finale.
 * @returns La valeur debouncée après le délai spécifié.
 */
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
