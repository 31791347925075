import React from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import arrive from "../../../asset/arrive.png";
import depart from "../../../asset/depart.png";
import transparent from "../../../asset/transparent.png";

import "leaflet-routing-machine";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: null,
  iconUrl: transparent,
  shadowUrl: null,
});
const MiniMap = ({
  startLatitude,
  startLongitude,
  endLatitude,
  endLongitude,
}) => {
  const startCoordinates = [startLatitude, startLongitude];
  const endCoordinates = [endLatitude, endLongitude];
  console.log(startCoordinates, endCoordinates);

  const customDepartIcon = L.icon({
    iconUrl: depart,
    shadowUrl: null,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });

  // Icône personnalisée pour l'arrivée
  const customArriveIcon = L.icon({
    iconUrl: arrive,
    shadowUrl: null,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });

  const RoutingMachine = () => {
    const map = useMap();

    L.Routing.control({
      waypoints: [
        L.latLng(startCoordinates[0], startCoordinates[1]),
        L.latLng(endCoordinates[0], endCoordinates[1]),
      ],
      routeWhileDragging: false, // Désactiver le calcul d'itinéraire en glissant
      show: false, // Ne pas afficher les instructions d'itinéraire
      addWaypoints: false, // Désactiver l'ajout de points d'itinéraire supplémentaires
      draggableWaypoints: false, // Désactiver le déplacement des points d'itinéraire
      waypointMode: "none",
    }).addTo(map);

    return null;
  };

  return (
    <MapContainer
      center={startCoordinates}
      zoom={8}
      style={{ width: "100%", height: "300px" }}
    >
      <TileLayer
        attribution='  &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
        minZoom={0}
        maxZoom={22}
        subdomains="abcd"
      />
      <Marker icon={customArriveIcon} position={startCoordinates}>
        <Popup>Départ</Popup>
      </Marker>
      <Marker icon={customDepartIcon} position={endCoordinates}>
        <Popup>Arrivée</Popup>
      </Marker>
      <RoutingMachine />
    </MapContainer>
  );
};

export default MiniMap;
