import React, { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  PencilIcon,
  EyeIcon,
  TrashIcon,
  CodeBracketSquareIcon,
  ChartBarIcon,
} from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import SimulateurTaxi from "./TaxiWidget/SimulateurTaxi";
import moment from "moment";
import axios from "axios";
import { toast } from "sonner";
import Modal from "../../Component/Modal/Modal";
import EditWidget from "./TaxiWidget/EditWidget";
import useFetch from "../../Component/Hooks/useFetch";
import Skeleton from "./Loader/Skeleton";
import Lottie from "lottie-react";
import LOCALVTC from "../../asset/LocalVTC.json";
import IframeCode from "./TaxiWidget/IframeCode";
import RightModal from "./TaxiWidget/RightModal";
import Statistiques from "./TaxiWidget/Statistiques";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListOl } from "@fortawesome/free-solid-svg-icons";
const TABS = [
  {
    label: "Tous",
    value: "all",
  },
  {
    label: "Taxi-VTC",
    value: "Widget Taxi",
  },
];

const TABLE_HEAD = [
  "Widget",
  "Type",
  "Statut",
  "Date de création",
  "Statistiques",
  "Visualiser",
  "Intégrer",
  "Editer",
  "Supprimer",
];

const ListWidget = ({ userId }) => {
  const [widgets, setWidgets] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsisEditModalOpen] = useState(false);
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
  const [isStatModalOpen, setIsStatModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleModal = (widgetId) => {
    setIsModalOpen((prev) => ({ ...prev, [widgetId]: !prev[widgetId] }));
  };

  const toggleCodeModal = (widgetId) => {
    setIsCodeModalOpen((prev) => ({ ...prev, [widgetId]: !prev[widgetId] }));
  };

  const toggleStatModal = (widgetId) => {
    setIsStatModalOpen((prev) => ({ ...prev, [widgetId]: !prev[widgetId] }));
  };

  const toggleEditModal = (widgetId) => {
    setIsisEditModalOpen((prev) => ({ ...prev, [widgetId]: !prev[widgetId] }));
  };

  const { loading, data } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/widgets/taxi/`
  );

  useEffect(() => {
    if (data) {
      setWidgets(data.widgets);
    }
  }, [data]);

  const handleDeleteWidget = async (widgetId) => {
    try {
      const confirmed = window.confirm(
        "Êtes-vous sûr de vouloir supprimer ce widget?"
      );

      if (confirmed) {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi/delete/${widgetId}/user/${userId}`
        );

        if (response.status === 200) {
          setWidgets((prevWidgets) =>
            prevWidgets.filter((widget) => widget._id !== widgetId)
          );
          toast.success("Widget supprimé");
        } else {
          toast.error("Erreur lors de la suppression du widget");
        }
      }
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  return (
    <Card className=" w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex items-center justify-between gap-8">
          <div>
            <Typography variant="h5" color="blue-gray">
              <FontAwesomeIcon icon={faListOl} /> Liste des widgets
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              Visualiser et modifier tous vos widgets
            </Typography>
          </div>
          <div className="flex shrink-0 flex-col gap-2 sm:flex-row p-7">
            <Link to="/createTaxiWidget">
              <div className="relative inline-flex group">
                <div className="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>
                <p className="relative inline-flex items-center justify-center px-8 py-2 text-md font-bold text-white transition-all duration-200 bg-gray-900 font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                  Créer un widget
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
          <Tabs value="all" className="w-2/5">
            <TabsHeader>
              {TABS.map(({ label, value }) => (
                <Tab key={value} value={value}>
                  {label}
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>
          <div className="w-full md:w-72">
            <Input
              label="Rechercher"
              icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              placeholder="Rechercher un widget"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className=" px-0">
        <table className="mt-4 w-full min-w-max table-auto text-center">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="8" className="text-center w-full">
                  <Skeleton />
                </td>
              </tr>
            ) : widgets.filter((widget) =>
                widget.title.toLowerCase().includes(searchTerm.toLowerCase())
              ).length > 0 ? (
              widgets
                .filter((widget) =>
                  widget.title.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map(({ _id, title, type, isOnline, createdAt }, index) => {
                  const formattedDate = moment(createdAt).format("DD/MM/YYYY");
                  const isLast = index === widgets?.length - 1;
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50 text-center items-center";

                  return (
                    <tr
                      key={_id}
                      className="odd:bg-gray-50 even:bg-slate-50 hover:bg-black hover:bg-opacity-5 text-center"
                    >
                      <td className={classes}>
                        <div className="flex items-center gap-3 justify-center">
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold uppercase"
                            >
                              {title}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {type === "Widget Taxi" ? (
                              <Lottie
                                animationData={LOCALVTC}
                                className="mx-auto h-10 w-auto"
                                loop={true}
                              />
                            ) : (
                              type
                            )}
                          </Typography>
                        </div>
                      </td>
                      <td
                        className={`${classes}  flex items-center justify-center`}
                      >
                        <div className="w-max">
                          <Chip
                            variant="ghost"
                            size="sm"
                            value={isOnline ? "Actif" : "Désactivé"}
                            color={isOnline ? "green" : "blue-gray"}
                          />
                        </div>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          🗓️ {formattedDate}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Tooltip content="Statistiques">
                          <IconButton
                            variant="text"
                            onClick={() => toggleStatModal(_id)}
                          >
                            <ChartBarIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                        {isStatModalOpen[_id] && (
                          <Modal
                            isOpen={isStatModalOpen[_id]}
                            onClose={() => toggleStatModal(_id)}
                          >
                            <Statistiques widgetId={_id} />
                          </Modal>
                        )}
                      </td>

                      <td className={classes}>
                        <Tooltip content="Visualiser le widget">
                          <IconButton
                            variant="text"
                            onClick={() => toggleModal(_id)}
                          >
                            <EyeIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                        {isModalOpen[_id] && (
                          <Modal
                            isOpen={isModalOpen[_id]}
                            onClose={() => toggleModal(_id)}
                          >
                            <SimulateurTaxi widgetId={_id} />
                          </Modal>
                        )}
                      </td>
                      <td className={classes}>
                        <Tooltip content="Code d'intégration">
                          <IconButton
                            variant="text"
                            onClick={() => toggleCodeModal(_id)}
                          >
                            <CodeBracketSquareIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                        {isCodeModalOpen[_id] && (
                          <Modal
                            isOpen={isCodeModalOpen[_id]}
                            onClose={() => toggleCodeModal(_id)}
                          >
                            <IframeCode widgetId={_id} />
                          </Modal>
                        )}
                      </td>
                      <td className={classes}>
                        <IconButton
                          variant="text"
                          onClick={() => toggleEditModal(_id)}
                        >
                          <PencilIcon className="h-4 w-4" />
                        </IconButton>
                        {isEditModalOpen[_id] && (
                          <RightModal
                            key={_id}
                            widgetId={_id}
                            editMode={true}
                            onClose={() => toggleEditModal(_id)}
                          >
                            <EditWidget widgetId={_id} />
                          </RightModal>
                        )}
                      </td>
                      <td className={classes}>
                        <Tooltip content="Supprimer le widget">
                          <IconButton
                            variant="text"
                            className="hover:text-red-400"
                            onClick={() => handleDeleteWidget(_id)}
                          >
                            <TrashIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td colSpan="8" className="text-center p-8 font-bold">
                  Aucun résultat pour cette recherche 😕
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page 1 sur 1
        </Typography>
        <div className="flex gap-2">
          <Button variant="outlined" size="sm">
            Precedent
          </Button>
          <Button variant="outlined" size="sm">
            Suivant
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

export default ListWidget;
