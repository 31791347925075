import React, { useEffect } from "react";

const MAX_ADDRESS_SUGGESTIONS = 5;

const AddressInput = ({
  label,
  value,
  onChange,
  onInput,
  suggestions,
  isSearching,
  handleSuggestionClick,
  color,
  fieldError,
  onCloseSuggestions,
}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".suggestions-list") &&
        onCloseSuggestions &&
        !event.target.classList.contains("suggestion-item")
      ) {
        onCloseSuggestions();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseSuggestions]);

  return (
    <div className="relative">
      <label
        htmlFor={label}
        className="mb-1 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-black last:mr-0 mr-1"
        style={{
          backgroundColor: color?.btnColor,
          color: color?.labelColor,
        }}
      >
        {label} *
      </label>
      <input
        type="text"
        id={label}
        name={label}
        value={value}
        onChange={onChange}
        onInput={onInput}
        className="w-full border rounded-md px-3 py-2 text-gray-800"
        required
      />
      {fieldError && (
        <span className="text-red-300">Impossible de trouver l'adresse.</span>
      )}
      {suggestions.length > 0 && !isSearching && value.length >= 5 && (
        <ul className="suggestions-list absolute left-0 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-md p-2 z-10 text-gray-800 text-start">
          {suggestions
            .slice(0, MAX_ADDRESS_SUGGESTIONS)
            .map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className="suggestion-item px-2 py-2 cursor-pointer hover:bg-gray-100 truncate odd:bg-gray-100 even:bg-slate-100"
              >
                {suggestion}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default AddressInput;
