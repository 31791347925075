import { Link } from "react-router-dom";
import useFetch from "../../../Component/Hooks/useFetch";
import Skeleton from "../Loader/Skeleton";
import FirstStats from "./Chart/FirstStats";
import TaxiChart from "./Chart/TaxiChart";

const AdminDashboard = () => {
  const { loading, error, data } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/widgets/taxi`
  );

  const totalWidgets = data?.widgets?.length;
  const totalPrice = totalWidgets * 10;
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const threeMonthsAgo = new Date(currentYear, currentMonth - 3, 1);
  const filteredWidgets = data?.widgets?.filter((widget) => {
    const widgetDate = new Date(widget.createdAt);
    return widgetDate >= threeMonthsAgo;
  });
  const monthlyWidgets = filteredWidgets?.length;

  const taxiWidgets = data?.widgets?.filter(
    (widget) => widget.type === "Widget Taxi"
  );
  const taxiList = taxiWidgets?.map((widget) => widget);
  const totalTaxiWidgets = taxiWidgets?.length;

  return (
    <div className="w-full bg-gray-200">
      <div className="flex justify-between items-center mx-5">
        <div className=" font-bold tracking-tight text-gray-900 sm:text-5xl md:text-2xl p-6">
          <h1 className="block md:text-4xl">
            Admin
            <span className="text-transparent bg-clip-text bg-gradient-to-tr to-cyan-500 from-blue-600 ml-2">
              Dashboard
            </span>
          </h1>
          <h2>Statistiques d'activation</h2>
        </div>
        <div className="button">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
            <Link to="/createUser">Créer un utilisateur</Link>
          </button>
        </div>
      </div>
      {loading ? (
        <div>
          <Skeleton />
        </div>
      ) : (
        <div className="flex flex-col">
          <div>
            <FirstStats
              totalWidgets={totalWidgets}
              totalPrice={totalPrice}
              monthlyWidgets={monthlyWidgets}
            />
          </div>
          <div>
            <TaxiChart
              totalWidgets={totalWidgets}
              totalPrice={totalPrice}
              monthlyWidgets={monthlyWidgets}
              totalTaxiWidgets={totalTaxiWidgets}
              taxiList={taxiList}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
