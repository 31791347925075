import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import ColorPicker from "../../../Component/ColorPicker/ColorPicker";
import Modal from "../../../Component/Modal/Modal";
import IframeCode from "./IframeCode";
import Tuto from "../../../asset/tuto.png";
import RightModal from "./RightModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRoute,
  faCar,
  faEnvelope,
  faPhone,
  faPerson,
  faSuitcase,
  faPalette,
  faEyeDropper,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const CreateWidget = ({ userId }) => {
  const user = useSelector((state) => state.userReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [widgetData, setWidgetData] = useState({
    userId: userId,
    title: "",
    pricePerKmDay: 0,
    pricePerKmNight: 0,
    pricePerKmSunday: 0,
    pricePerKmRoundTripDay: 0,
    pricePerKmRoundTripSunday: 0,
    isOnline: "online",
    type: "Widget Taxi",
    btnColor: "",
    backgroundColor: "",
    labelColor: "",
  });
  const [activeColorPicker, setActiveColorPicker] = useState(null);
  const [colorPickers, setColorPickers] = useState({
    backgroundColor: false,
    btnColor: false,
    labelColor: false,
  });
  const [widgetId, setWidgetId] = useState("");
  const [formChanged, setFormChanged] = useState(false);

  const handleColorPickerToggle = (fieldName) => {
    setColorPickers((prevPickers) => ({
      ...Object.keys(prevPickers).reduce((acc, key) => {
        acc[key] = key === fieldName ? !prevPickers[key] : false;
        return acc;
      }, {}),
    }));

    setActiveColorPicker(fieldName);
  };

  const handleColorChange = (color) => {
    setWidgetData((prevData) => ({
      ...prevData,
      [activeColorPicker]: color.hex,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWidgetData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    handleFormChange();
  };

  const handleFormChange = () => {
    setFormChanged(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(widgetData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        toast.success("Widget créé avec succès");

        const widgetId = data.widget._id;
        setWidgetId(widgetId);
      } else {
        switch (response.status) {
          case 401:
            toast.error("Vous n'êtes pas connecté");
            break;
          case 400:
            toast.error("Attention, un widget existe déjà avec ce nom.");
            break;
          default:
            toast.error("Une erreur est survenue");
            break;
        }
      }
      setFormChanged(false);
    } catch (error) {
      console.error("Erreur:", error);
    }

    setIsLoading(false);
  };

  return (
    <div className="p-4 w-full flex gap-10">
      <div className="w-full">
        <Link to={`/`}>
          <button className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-600 my-1 w-[200px] flex justify-center items-center gap-2">
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            <p>Revenir à la liste</p>
          </button>
        </Link>
        {user.user.isPremium ? (
          <form
            onSubmit={handleSubmit}
            className="space-y-4 flex justify-center items-center mt-5"
          >
            <div
              className="shadow-2xl rounded p-9 pt-6 pb-8 mb-4 flex flex-col my-2 w-[80%] mt-2"
              style={{
                backgroundColor: widgetData.backgroundColor || "bg-white",
                color: widgetData.labelColor || "black",
              }}
            >
              <div className="-mx-3 md:flex mb-3">
                <div className="md:w-full px-3 flex flex-col items-center justify-center">
                  <label
                    className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                    htmlFor="grid-password"
                  >
                    Donner un titre a votre simulateur *
                  </label>
                  <input
                    className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    id="grid-password"
                    type="texte"
                    name="title"
                    placeholder="Code sage - Entreprise"
                    value={widgetData.title}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="flex justify-between gap-20 mb-6">
                <div className="leftForm">
                  <div className="-mx-3 md:flex mb-3 mt-6 items-center justify-center strike">
                    <span
                      className="text-sm font-extrabold py-1 px-2 uppercase rounded text-black last:mr-0 mr-1 flex gap-4 justify-center items-center"
                      style={{
                        color: widgetData.labelColor || "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faRoute} /> Prix aller-simple
                    </span>
                  </div>
                  <div className="-mx-3 md:flex mb-3 flex">
                    <div className="md:w-1/2 px-3 mb-3 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                        htmlFor="pricePerKmDay"
                      >
                        Prix par / kilomètre*
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">€</span>
                        </div>
                        <input
                          type="number"
                          step=".01"
                          min="0"
                          name="pricePerKmDay"
                          id="price"
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                          onChange={handleInputChange}
                          required
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center">
                          <label htmlFor="currency" className="sr-only">
                            Devise
                          </label>
                          <select
                            id="currency"
                            name="currency"
                            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                          >
                            <option>EUR</option>
                          </select>
                        </div>
                      </div>
                      <p className="text-red text-xs italic">
                        (7h à 19h, du lundi au samedi)
                      </p>
                    </div>
                    <div className="md:w-1/2 px-3">
                      <label
                        className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                        htmlFor="pricePerKmNight"
                      >
                        Prix / kilomètre de nuit*
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">€</span>
                        </div>
                        <input
                          type="number"
                          step=".01"
                          min="0"
                          name="pricePerKmNight"
                          id="price"
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                          onChange={handleInputChange}
                          required
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center">
                          <label htmlFor="currency" className="sr-only">
                            Devise
                          </label>
                          <select
                            id="currency"
                            name="currency"
                            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                          >
                            <option>EUR</option>
                          </select>
                        </div>
                      </div>
                      <p className="text-red text-xs italic">
                        (19h à 7h + Dimanche)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="rightForm">
                  <div className="-mx-3 md:flex mb-3 mt-6 items-center justify-center strike">
                    <span
                      className="text-sm font-extrabold py-1 px-2 uppercase rounded text-black last:mr-0 mr-1 flex gap-4 justify-center items-center"
                      style={{
                        color: widgetData.labelColor || "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faRoute} /> Prix aller-retour
                    </span>
                  </div>
                  <div className="-mx-3 md:flex mb-3">
                    <div className="md:w-1/2 px-3 mb-3 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                        htmlFor="pricePerKmRoundTripDay"
                      >
                        Prix / kilomètre*
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">€</span>
                        </div>
                        <input
                          type="number"
                          step=".01"
                          min="0"
                          name="pricePerKmRoundTripDay"
                          id="price"
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                          onChange={handleInputChange}
                          required
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center">
                          <label htmlFor="currency" className="sr-only">
                            Devise
                          </label>
                          <select
                            id="currency"
                            name="currency"
                            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                          >
                            <option>EUR</option>
                          </select>
                        </div>
                      </div>
                      <p className="text-red text-xs italic">
                        (7h à 19h, du lundi au samedi)
                      </p>
                    </div>
                    <div className="md:w-1/2 px-3">
                      <label
                        className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                        htmlFor="pricePerKmRoundTripSunday"
                      >
                        Prix / kilomètre de nuit*
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">€</span>
                        </div>
                        <input
                          type="number"
                          step=".01"
                          min="0"
                          name="pricePerKmRoundTripSunday"
                          id="price"
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                          onChange={handleInputChange}
                          required
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center">
                          <label htmlFor="currency" className="sr-only">
                            Devise
                          </label>
                          <select
                            id="currency"
                            name="currency"
                            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                          >
                            <option>EUR</option>
                          </select>
                        </div>
                      </div>
                      <p className="text-red text-xs italic">
                        (19h à 7h + Dimanche)
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between gap-20 mb-6">
                <div className="leftForm w-1/2">
                  <div className="-mx-3 md:flex mb-3 mt-6 items-center justify-center strike">
                    <span
                      className="text-sm font-extrabold py-1 px-2 uppercase rounded text-black last:mr-0 mr-1 mb-4  flex gap-4 justify-center items-center"
                      style={{
                        color: widgetData.labelColor || "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faCar} /> Tarif prise en charge
                    </span>
                  </div>
                  <div className="-mx-3 md:flex mb-3 flex">
                    <div className="md:w-full px-3 mb-3 md:mb-0">
                      <div className="relative mt-2 rounded-md shadow-sm w-full">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">€</span>
                        </div>
                        <input
                          type="number"
                          step=".01"
                          min="0"
                          name="pricePriseEnCharge"
                          id="price"
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                          onChange={handleInputChange}
                          required
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center">
                          <label htmlFor="currency" className="sr-only">
                            Devise
                          </label>
                          <select
                            id="currency"
                            name="currency"
                            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                          >
                            <option>EUR</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rightForm w-1/2">
                  <div className="-mx-3 md:flex mb-3 mt-6 items-center justify-center strike">
                    <span
                      className="text-sm font-extrabold py-1 px-2 uppercase rounded text-black last:mr-0 mr-1 flex gap-4 justify-center items-center"
                      style={{
                        color: widgetData.labelColor || "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faPerson} /> Options
                      supplémentaires <FontAwesomeIcon icon={faSuitcase} />
                    </span>
                  </div>
                  <div className="-mx-3 md:flex mb-2">
                    <div className="md:w-1/2 px-3 mb-3 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                        htmlFor="pricePerPassenger"
                      >
                        Prix par passager *
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">€</span>
                        </div>
                        <input
                          type="number"
                          step=".01"
                          min="0"
                          name="pricePerPassenger"
                          id="price"
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                          onChange={handleInputChange}
                          required
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center">
                          <label htmlFor="currency" className="sr-only">
                            Devise
                          </label>
                          <select
                            id="currency"
                            name="currency"
                            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                          >
                            <option>EUR</option>
                          </select>
                        </div>
                      </div>
                      <p className="text-red text-xs italic">
                        (A partir du 5eme passager)
                      </p>
                    </div>
                    <div className="md:w-1/2 px-3">
                      <label
                        className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                        htmlFor="pricePerBaggage"
                      >
                        Prix par bagage *
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">€</span>
                        </div>
                        <input
                          type="number"
                          step=".01"
                          min="0"
                          name="pricePerBaggage"
                          id="price"
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                          onChange={handleInputChange}
                          required
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center">
                          <label htmlFor="currency" className="sr-only">
                            Devise
                          </label>
                          <select
                            id="currency"
                            name="currency"
                            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                          >
                            <option>EUR</option>
                          </select>
                        </div>
                      </div>
                      <p className="text-red text-xs italic">
                        (Au dela de 3 bagages)
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="-mx-3 md:flex mb-3 mt-6 items-center justify-center w-1/2 strike">
                <span
                  className="text-sm font-extrabold py-1 px-2 uppercase rounded text-black last:mr-0 mr-1 flex gap-4 justify-center items-center"
                  style={{
                    color: widgetData.labelColor || "black",
                  }}
                >
                  <FontAwesomeIcon icon={faEnvelope} /> Informations de contact
                  <FontAwesomeIcon icon={faPhone} />
                </span>
              </div>
              <div className="-mx-3 md:flex mb-6  px-3 md:mb-0">
                <div className=" px-3 mb-3 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                    htmlFor="contactMail"
                  >
                    Mail *
                  </label>
                  <input
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    id="contactMail"
                    type="email"
                    name="contactMail"
                    placeholder="mail@example.com"
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="px-3 mb-3 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                    htmlFor="contactPhone"
                  >
                    Téléphone *
                  </label>
                  <input
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    id="contactPhone"
                    type="tel"
                    pattern="[0-9]{10}"
                    name="contactPhone"
                    placeholder="+33"
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="-mx-3 md:flex mb-3 items-center justify-center strike mt-10">
                <span
                  className="text-sm font-extrabold py-1 px-2 uppercase rounded text-black  last:mr-0 mr-1 flex gap-4 justify-center items-center"
                  style={{
                    color: widgetData.labelColor || "black",
                  }}
                >
                  <FontAwesomeIcon icon={faPalette} /> Customisation
                </span>
              </div>
              <div className="-mx-3 flex mb-5">
                <div className="md:w-1/3 px-3 mb-3 md:mb-0">
                  <ColorPicker
                    label="Couleur de fond"
                    color={widgetData.backgroundColor}
                    onColorChange={(color) => handleColorChange(color)}
                    isOpen={colorPickers.backgroundColor}
                    togglePicker={() =>
                      handleColorPickerToggle("backgroundColor")
                    }
                    placeholder={<FontAwesomeIcon icon={faEyeDropper} />}
                  />
                </div>
                <div className="md:w-1/3 px-3 mb-3 md:mb-0">
                  <ColorPicker
                    label="Couleur des boutons"
                    color={widgetData.btnColor}
                    onColorChange={(color) => handleColorChange(color)}
                    isOpen={colorPickers.btnColor}
                    togglePicker={() => handleColorPickerToggle("btnColor")}
                    placeholder={<FontAwesomeIcon icon={faEyeDropper} />}
                  />
                </div>
                <div className="md:w-1/3 px-3 mb-3 md:mb-0">
                  <ColorPicker
                    label="Couleur du texte"
                    color={widgetData.labelColor}
                    onColorChange={(color) => handleColorChange(color)}
                    isOpen={colorPickers.labelColor}
                    togglePicker={() => handleColorPickerToggle("labelColor")}
                    placeholder={<FontAwesomeIcon icon={faEyeDropper} />}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="bg-black text-white px-4 py-2 rounded-md hover:bg-green-800 my-1 w-1/3 flex justify-center items-center mx-auto"
                style={{
                  backgroundColor: widgetData.btnColor || "black",
                  color: widgetData.labelColor || "white",
                  cursor: formChanged ? "pointer" : "not-allowed",
                }}
                disabled={isLoading || !formChanged}
              >
                {isLoading
                  ? "Création en cours..."
                  : formChanged
                  ? "Créer le widget"
                  : "Compléter le formulaire"}
              </button>
            </div>
          </form>
        ) : (
          <p>Vous n'êtes pas encore authorisé</p>
        )}
        <div className="tutoriel flex w-full mt-10 justify-center">
          <RightModal image={Tuto} tutoMode={true} />
        </div>
      </div>

      {widgetId && (
        <Modal
          title="Code iframe"
          isOpen={true}
          onClose={() => setWidgetId("")}
          children={<IframeCode widgetId={widgetId} />}
        />
      )}
    </div>
  );
};

export default CreateWidget;
